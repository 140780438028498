import {
  findPathBySelectorType,
  PRODUCT_SELECTOR_TYPE_CATEGORY
} from "@constants";
import { isVideo } from "@utils/image";
import { escapeReact } from "@utils/react";

export default ({ imgParams, i18n, pathfinder }) => {
  const _i18n = i18n.pages.BuyGuide;

  const escapeReactRoute = str => escapeReact(str, pathfinder);

  const buildImgSrc = imgName =>
    imgParams.imgDir + "/pages/buy-guide/".concat(imgName);

  const buildImg = (imgName, sizes, aspect) =>
    imgName
      ? {
          ...(isVideo(imgName)
            ? {
                url: imgName,
                embededVideo: true,
                allowfullscreen: true,
                sizes
              }
            : {
                src: buildImgSrc(imgName),
                cloudinary: imgParams.cloudinary,
                sizes,
                aspect,
                version: true
              })
        }
      : null;

  const buildFeaturedProductCategory = (slug, imgName, colspan) => ({
    text: null,
    url: findPathBySelectorType(
      pathfinder,
      PRODUCT_SELECTOR_TYPE_CATEGORY,
      slug
    ),
    img: {
      src: buildImgSrc(imgName),
      cloudinary: imgParams.cloudinary,
      sizes: {
        mobilePortrait: 336,
        mobileLandscape: 501,
        tabletPortrait: 336,
        tabletLandscape: 456,
        desktop: 546
      },
      aspect: 0.738,
      version: true
    },
    default_colspan: colspan,
    className: { card: "h-auto" }
  });

  const result = {
    lead: {
      title: _i18n.lead.TITLE,
      text: escapeReactRoute(_i18n.lead.TEXT)
    },
    whatIsRangeCooker: {
      lead: {
        title: _i18n.whatIsRangeCooker.lead.TITLE,
        text: escapeReactRoute(_i18n.whatIsRangeCooker.lead.TEXT),
        img: buildImg(
          _i18n.whatIsRangeCooker.lead.IMG,
          {
            mobilePortrait: 315,
            mobileLandscape: 470,
            tabletPortrait: 200,
            tabletLandscape: 280,
            desktop: 340
          },
          0.7484
        )
      },
      choiceOfDetails: {
        lead: {
          title: _i18n.whatIsRangeCooker.choiceOfDetails.lead.TITLE,
          text: escapeReactRoute(
            _i18n.whatIsRangeCooker.choiceOfDetails.lead.TEXT
          )
        },
        table: {
          visible: _i18n.whatIsRangeCooker.choiceOfDetails.table.visible,
          header: (
            _i18n.whatIsRangeCooker.choiceOfDetails.table.header || []
          ).map(item => item.TITLE),
          rows: (_i18n.whatIsRangeCooker.choiceOfDetails.table.rows || []).map(
            item => item.cols.map(col => col.TEXT)
          )
        }
      },
      sizes: {
        lead: {
          title: _i18n.whatIsRangeCooker.sizes.lead.TITLE,
          text: escapeReactRoute(_i18n.whatIsRangeCooker.sizes.lead.TEXT),
          img: buildImg(
            _i18n.whatIsRangeCooker.sizes.lead.IMG,
            {
              mobilePortrait: 315,
              mobileLandscape: 480,
              tabletPortrait: 200,
              tabletLandscape: 280,
              desktop: 340
            },
            0.5912
          )
        }
      }
    },
    whatStoveToChoose: {
      lead: {
        title: _i18n.whatStoveToChoose.lead.TITLE,
        text: escapeReactRoute(_i18n.whatStoveToChoose.lead.TEXT),
        img: buildImg(
          _i18n.whatStoveToChoose.lead.IMG,
          {
            mobilePortrait: 272,
            mobileLandscape: 272,
            tabletPortrait: 272,
            tabletLandscape: 200,
            desktop: 272
          },
          0.4522
        )
      },
      paragraphs: _i18n.whatStoveToChoose.paragraphs.map(item => ({
        title: item.TITLE,
        text: escapeReactRoute(item.TEXT),
        img: item.IMG
          ? buildImg(
              item.IMG,
              {
                mobilePortrait: 345,
                mobileLandscape: 452,
                tabletPortrait: 295,
                tabletLandscape: 415,
                desktop: 520
              },
              0.351
            )
          : null
      }))
    },
    whatOvenToChoose: {
      lead: {
        title: _i18n.whatOvenToChoose.lead.TITLE,
        text: escapeReactRoute(_i18n.whatOvenToChoose.lead.TEXT),
        img: buildImg(
          _i18n.whatOvenToChoose.lead.IMG,
          {
            mobilePortrait: 272,
            mobileLandscape: 272,
            tabletPortrait: 272,
            tabletLandscape: 200,
            desktop: 272
          },
          0.4522
        )
      },
      paragraphs: _i18n.whatOvenToChoose.paragraphs.map(item => ({
        title: item.TITLE,
        text: escapeReactRoute(item.TEXT),
        img: item.IMG
          ? buildImg(
              item.IMG,
              {
                mobilePortrait: 345,
                mobileLandscape: 452,
                tabletPortrait: 295,
                tabletLandscape: 415,
                desktop: 520
              },
              0.351
            )
          : null
      }))
    },
    builtIn: {
      lead: {
        title: _i18n.builtIn.lead.TITLE,
        text: escapeReactRoute(_i18n.builtIn.lead.TEXT),
        img: buildImg(
          _i18n.builtIn.lead.IMG,
          {
            mobilePortrait: 272,
            mobileLandscape: 272,
            tabletPortrait: 272,
            tabletLandscape: 200,
            desktop: 272
          },
          0.4522
        )
      },
      items: _i18n.builtIn.items.map(item => ({
        title: item.TITLE,
        alt: item.TITLE,
        text: item.TEXT,
        ...buildFeaturedProductCategory(
          item.CATEGORY_SLUG,
          item.IMG,
          _i18n.builtIn.items.length
            ? Math.ceil(12 / _i18n.builtIn.items.length)
            : 12
        )
      }))
    },
    kitchenSolutions: {
      lead: {
        title: _i18n.kitchenSolutions.lead.TITLE,
        text: escapeReactRoute(_i18n.kitchenSolutions.lead.TEXT),
        img: buildImg(
          _i18n.kitchenSolutions.lead.IMG,
          {
            mobilePortrait: 272,
            mobileLandscape: 272,
            tabletPortrait: 272,
            tabletLandscape: 200,
            desktop: 272
          },
          0.4522
        )
      },
      paragraphs: _i18n.kitchenSolutions.paragraphs.map(item => ({
        title: item.TITLE,
        text: escapeReactRoute(item.TEXT),
        img: item.IMG
          ? buildImg(
              item.IMG,
              {
                mobilePortrait: 345,
                mobileLandscape: 452,
                tabletPortrait: 295,
                tabletLandscape: 415,
                desktop: 520
              },
              0.351
            )
          : null
      }))
    },
    ourBrands: {
      lead: {
        title: _i18n.ourBrands.lead.TITLE,
        text: escapeReactRoute(_i18n.ourBrands.lead.TEXT),
        img: buildImg(
          _i18n.ourBrands.lead.IMG,
          {
            mobilePortrait: 272,
            mobileLandscape: 272,
            tabletPortrait: 272,
            tabletLandscape: 200,
            desktop: 272
          },
          0.4522
        )
      }
    },
    faq: {
      lead: {
        title: _i18n.faq.lead.TITLE,
        text: escapeReactRoute(_i18n.faq.lead.TEXT)
      },
      items: _i18n.faq.items.map(item => ({
        title: item.TITLE,
        text: escapeReactRoute(item.TEXT)
      }))
    }
  };

  return result;
};
